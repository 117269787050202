import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
// import "@/mixins"
import "@/assets/scss/app.scss"
import "@/components"
import DataTable from "@cheezytony/vue-tailwind-datatable"
import Notifications from "vue-notification"

Vue.config.productionTip = false

Vue.use(DataTable)
Vue.use(Notifications)

new Vue({
  router,
  store,
  mounted() {
    this.$notify({
      title: "Important message",
      text: "Welcome Admin, !"
    })
  },
  render: (h) => h(App)
}).$mount("#app")
